import moment from "moment";
import { TIMEZONE, LANG } from "redux/constants/Auth";

export const CategorizeData = (data, dateFormat, finalData, origin) => {
  // dateFormat = 1 : MMMM DD, YYYY
  // dateFormat = 2 : dddd, MMMM DD, YYYY
  const userTimeZone = localStorage.getItem(TIMEZONE);
  const userLanguage = localStorage.getItem(LANG);
  if (dateFormat === 1 ) {
    var options = {  year: 'numeric', month: 'short', day: 'numeric' };
    options.timeZone = userTimeZone;
  } else {
    var options = {  weekday:'long', year: 'numeric', month: 'long', day: 'numeric' };
    options.timeZone = userTimeZone;
  }
  if((Array.isArray(data) && data.length)) {
    var dateTemp =  convertTimeZoneVar(data[0].date, options)
    var newData = [];
    data.map((item, index , rows) => {
      const newItem = {...item};
      if(!newItem.language || newItem.language === userLanguage){
        if (convertTimeZoneVar (newItem.date, options) === dateTemp ) {
          newItem.date = moment(convertTimeZone(newItem.date)).format("HH:mm");
          newData.push (newItem);
        } else {
          let obj = {date: dateTemp, data: newData};
          finalData.push(obj);
          newData = [];
          dateTemp =  convertTimeZoneVar (newItem.date, options);
          newItem.date = moment(convertTimeZone(newItem.date)).format("HH:mm");
          newData.push (newItem);
        }
        // if (index + 1 === rows.length) {
        //   let obj = {date: dateTemp, data: newData};
        //   finalData.push(obj);
        // }
      }
    })
    let obj = {date: dateTemp, data: newData};
    finalData.push(obj);
  }
    return finalData;
  };

export const convertTimeZone = (convertedDate) => {
  return new Date(convertedDate).toLocaleString("en-US", {timeZone: localStorage.getItem(TIMEZONE)});
}

export const convertTimeZoneVar = (convertedDate, options) => {
  return new Date(convertedDate).toLocaleString("en-US", options);
}