import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Bar} from 'react-chartjs-2';
import { 
  COLOR_BAR_1, COLOR_BAR_2, COLOR_BAR_3, COLOR_BAR_4, COLOR_BAR_5,
  COLOR_BAR_6, COLOR_BAR_7, COLOR_BAR_8, COLOR_BAR_9, COLOR_BAR_10,
  COLOR_BAR_11, COLOR_BAR_12, COLOR_BAR_13, COLOR_BAR_14, COLOR_BAR_15,
} from 'constants/ChartConstant';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const CurrnecyBarChart = ({ data }) => {
  const [categories, setCategories] = useState([]);
  const [currencyChange, setCurrencyChange] = useState([]);
  const [fillColorsStack, setFillColors] = useState([]);
  const [scaleRange, setScaleRange] = useState();
  const preDefinedColors = [COLOR_BAR_1, COLOR_BAR_2, COLOR_BAR_3, COLOR_BAR_4, COLOR_BAR_5, COLOR_BAR_6, COLOR_BAR_7, COLOR_BAR_8, COLOR_BAR_9, COLOR_BAR_10, COLOR_BAR_11, COLOR_BAR_12, COLOR_BAR_13, COLOR_BAR_14, COLOR_BAR_15];
  
useEffect(() => {
    if (data) {
      const newCat = [];
      const newCurrencyChange = [];
      const newColor = [];
      
      data.map(({ currency, currencyChange }) => {
        newCat.push(currency);
        newCurrencyChange.push(Number(currencyChange.replace("%","")));
      });
      
      const posNums = newCurrencyChange.filter(item => item > 0).length;
      for (var i =  8 - (8 - posNums); i < 8 + posNums ; i++) {newColor.push(preDefinedColors[i]);}
      
      setScaleRange(Math.max(Math.max(...newCurrencyChange),Math.abs(Math.min(...newCurrencyChange))));
      setCategories(newCat);
      setCurrencyChange(newCurrencyChange);
      setFillColors(newColor);
    }
  }, [data]);


  const chartData = {
    labels: categories,
    datasets: [
      {
        data: [0,0,0,0,0,0,0,0],
        backgroundColor: fillColorsStack,
        minBarLength: 25,
        datalabels: {
          color: '#fcfcfc',
          formatter: function(value, context) {
            return context.chart.data.labels[context.dataIndex];
          }
        }
      },
      {
        data: currencyChange,
        backgroundColor: fillColorsStack,
        datalabels: {
          color: '#fcfcfc',
          anchor: 'end',
          align: 'end',
          offset: 15,
          formatter: function(value) {
            return value + '%';
          }
        }
      }
    ],
  };

  const chartOptions = {
    plugins: [ChartDataLabels],
    responsive: true,
    plugins:{
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        categories: categories,
        stacked: true,
        ticks: {
          color: '#fcfcfc',
        }
      },
      yAxis: {
        min: -1 * Math.ceil(scaleRange),
        max: Math.ceil(scaleRange),
        stacked: true,
        ticks: {
          color: '#fcfcfc',
        }
      }
    }
  };


  return (
    <Bar options={chartOptions} data={chartData}/>
  );
};

export default CurrnecyBarChart;
