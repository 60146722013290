import React, { useEffect } from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from "react-redux";
import {  EditOutlined,  LogoutOutlined} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { setUserInfo, signOut } from "redux/actions/Auth";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "constants/ApiConstant";
import { useHistory } from "react-router";
import { AUTH_PREFIX_PATH, API_BASE_URL } from "configs/AppConfig";
import moment from 'moment';
import setLocale from "constants/Translate";

const menuItem = [
  {
    title: setLocale(true,"nav.profile.edit"),
    icon: EditOutlined,
    path: "/profile",
  }
];

export const NavProfile = ({ signOut }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.userInfo);

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={API_BASE_URL + userInfo.avatar}>{userInfo.fullName?.charAt(0)}</Avatar>
          <div className="pl-3">
            <h5 className="mb-0">{userInfo?.fullName}</h5>
            {userInfo.subscription ? 
            <span class="active-text-muted">{setLocale(true,"nav.profile.membership.text")}<br></br>{moment(userInfo.subscriptionExpiry).format("MMM-DD, YYYY")}</span> : 
            <span class="expired-text-muted">{setLocale(true,"nav.profile.membership.description.part1")}<br></br>{setLocale(true,"nav.profile.membership.description.part2")}</span> }
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item
            key={menuItem.length + 1}
            onClick={(e) => {
              signOut();
              history.push(`${AUTH_PREFIX_PATH}/login`);
            }}
          >
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">{setLocale(true,"nav.profile.signout")}</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  useEffect(() => {
    getUserProfile().then((res) => {
      if (res.status === 200) {
        dispatch(setUserInfo(res.data));
      }
    });
  }, [dispatch]);
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar size={45} src={API_BASE_URL + userInfo.avatar} >{userInfo.fullName?.charAt(0)}</Avatar>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
