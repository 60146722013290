import React, { useEffect, useState } from "react";
import { SlidersOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import SentimentBarChart from "./SentimentBarChart";
import { getSentimentAndRelative } from "constants/ApiConstant";
import { Menu, Tooltip } from "antd";
import setLocale from "constants/Translate";

const Sentiment = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sentimentData, setSentimentData] = useState([]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getSentimentAndRelative().then((res) => {
      if (res.status === 200) {
        setSentimentData(res.data);
      }
    });
    return () => {
      setSentimentData([]);
    };
  }, [isModalVisible]);

  return (
    <>
        <Menu mode="horizontal">
          <Menu.Item  onClick={showModal}>
            <Tooltip placement="topLeft" title={setLocale(true, "nav.tooltip.sentiment")}>
            <a href><SlidersOutlined className="nav-icon mr-0" /></a>
            </Tooltip>
          </Menu.Item>
        </Menu>
        <Modal
          title={setLocale(true, "sentiment.chart.title")}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={'80%'}
          footer={null}
          style={{ textAlign: "center" }}
        >
          <SentimentBarChart data={sentimentData} />
        </Modal>
    </>
  );
};

export default Sentiment;
