import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import {
    COLOR_1,
    COLOR_2,
    COLOR_3,
    COLOR_4,
    COLOR_5,
    COLOR_6,
    COLOR_7,
    COLOR_8,
  } from "constants/ChartConstant";
  import moment from 'moment';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const CurrencyLineChart = ({ data }) => {
    const [series, setSeries] = useState([]);
    const [priceTime, setPriceTime] = useState([]);
    const [scaleRange, setScaleRange] = useState();
    

  useEffect(() => {
      if (data.rateData) {

          const newTime = [];
          var today = new Date();
          var yesterday = new Date(today)
          yesterday.setDate(yesterday.getDate() - 1)
          const dateShow = (data.dayReq === 'today') ? moment(today).format("MMM DD") : moment(yesterday).format("MMM DD")
          data.rateData.map(({time}) => {newTime.push(`${dateShow}, ${time}`)});
          const uniqueTimes = Array.from(new Set(newTime));
          
          const seriesArray = [];
          const EUR = data.rateData.filter((c) => c.symbol === "EUR");
          const EURDATA = { label: "EUR", borderColor: COLOR_1, backgroundColor: COLOR_1, data: EUR.map((c) => c.rate), datalabels: {display: false}, };
          const USD = data.rateData.filter((c) => c.symbol === "USD");
          const USDDATA = { label: "USD", borderColor: COLOR_2, backgroundColor: COLOR_2, data: USD.map((c) => c.rate), datalabels: {display: false}, };
          const CHF = data.rateData.filter((c) => c.symbol === "CHF");
          const CHFDATA = { label: "CHF", borderColor: COLOR_3, backgroundColor: COLOR_3, data: CHF.map((c) => c.rate), datalabels: {display: false}, };
          const GBP = data.rateData.filter((c) => c.symbol === "GBP");
          const GBPDATA = { label: "GBP", borderColor: COLOR_4, backgroundColor: COLOR_4, data: GBP.map((c) => c.rate), datalabels: {display: false}, };
          const AUD = data.rateData.filter((c) => c.symbol === "AUD");
          const AUDDATA = { label: "AUD", borderColor: COLOR_5, backgroundColor: COLOR_5, data: AUD.map((c) => c.rate), datalabels: {display: false}, };
          const CAD = data.rateData.filter((c) => c.symbol === "CAD");
          const CADDATA = { label: "CAD", borderColor: COLOR_6, backgroundColor: COLOR_6, data: CAD.map((c) => c.rate), datalabels: {display: false}, };
          const NZD = data.rateData.filter((c) => c.symbol === "NZD");
          const NZDDATA = { label: "NZD", borderColor: COLOR_7, backgroundColor: COLOR_7, data: NZD.map((c) => c.rate), datalabels: {display: false}, };
          const JPY = data.rateData.filter((c) => c.symbol === "JPY");
          const JPYDATA = { label: "JPY", borderColor: COLOR_8, backgroundColor: COLOR_8, data: JPY.map((c) => c.rate), datalabels: {display: false}, };
          seriesArray.push(
              EURDATA,
              USDDATA,
              CHFDATA,
              GBPDATA,
              AUDDATA,
              CADDATA,
              NZDDATA,
              JPYDATA
          );
          
          const maxVals = [];
          const minVals = [];
          seriesArray.map(
            (item) => {
                item.tension = 0.1;
                item.borderWidth = 1;
                maxVals.push(Math.max(...item.data));
                minVals.push(Math.min(...item.data))
              }
            );
          
          setScaleRange(Math.max(Math.max(...maxVals),Math.abs(Math.min(...minVals))))
          setSeries(seriesArray);
          setPriceTime(uniqueTimes);
      }
    }, [data]);

    const chartData = {
      labels: priceTime,
      datasets: series,
    };

    const chartOptions = {
      responsive: true,
      elements: {
          point:{
              radius: 0
          }
      },
      interaction: {
        mode: 'index',
        intersect: false,
      },
      plugins: {
        legend: {
          position: 'right',
          labels: {
            color: '#fcfcfc',
          }
        }
      },
      scales: {
        xAxis: {
          ticks: {
            color: '#fcfcfc',
            callback: function(value, index) {
              return this.getLabelForValue(index).substring(8)}
          }
        },
        yAxis: {
          min: -1 * Math.ceil(Math.ceil(scaleRange)/10) * 10,
          max: Math.ceil(Math.ceil(scaleRange)/10) * 10,
          ticks: {
            color: '#fcfcfc',
          }
        }
      }
    };

  return (
    <Line options={chartOptions} data={chartData}/>
  );
};

export default CurrencyLineChart;
