import React, { useEffect, useState } from "react";
import { SwapOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { getSentimentAndRelative } from "constants/ApiConstant";
import CurrnecyBarChart from "./CurrencyBarChart";
import { Menu, Tooltip } from "antd";
import moment from "moment";
import { convertTimeZone } from "constants/CategorizeData";
import setLocale, {setLocaleJS} from "constants/Translate";
import {LANG} from "redux/constants/Auth";

const CurrencyRelative = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [relativeData, setRelativeData] = useState([]);
  const [titleText, setTitleText] = useState();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    getSentimentAndRelative().then((res) => {
      if (res.status === 200) {
        res.data.sort((a,b) => 
        (Number(a.currencyChange.replace("%","")) < Number(b.currencyChange.replace("%",""))) ?
        1 : (Number(a.currencyChange.replace("%","")) === Number(b.currencyChange.replace("%",""))) ? 1 : -1 ).reverse();
        setRelativeData(res.data);
        setTitleText(setLocaleJS(localStorage.getItem(LANG), "performance.chart.title") + moment(convertTimeZone(res.data[1].updatedAt)).format("YYYY-MM-DD, HH:mm"));
      }
    });
    return () => {
      setRelativeData([]);
    };
  }, [isModalVisible]);

  return (
    <>
      <Menu mode="horizontal">
          <Menu.Item  onClick={showModal}>
            <Tooltip placement="topLeft" title={setLocale(true, "nav.tooltip.performance")}>
            <a href><SwapOutlined className="nav-icon mr-0" /></a>
            </Tooltip>
          </Menu.Item>
        </Menu>
        <Modal
          title={titleText}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          width={1000}
          style={{ textAlign: "center" }}
        >
          <CurrnecyBarChart data={relativeData} />
        </Modal>
    </>
  );
};

export default CurrencyRelative;
