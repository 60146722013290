import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { AUTH_TOKEN, USEREMAIL } from "redux/constants/Auth";

const handleError = (err) => {
  if (err.response?.status === 401 && localStorage.getItem(AUTH_TOKEN)) {
    localStorage.removeItem(AUTH_TOKEN);
    window.location.href = "/login";
  }
  return err.response;
};

export const getUserProfile = () => {
    const result = axios
      .get(`${API_BASE_URL}/users/profile/${localStorage.getItem(USEREMAIL)}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` },
      })
      .then((res) => res)
      .catch((err) => handleError(err));
  
    return result;
  };
  
  export const putUserProfile = (data) => {
    const result = axios
      .put(
        `${API_BASE_URL}/users/profile/${localStorage.getItem(USEREMAIL)}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          },
        }
      )
      .then((res) => res)
      .catch((err) => handleError(err));
  
    return result;
  };
  
  export const uploadFile = (data) => {
    const result = axios
      .post(`${API_BASE_URL}/upload-file`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` },
      })
      .then((res) => res)
      .catch((err) => handleError(err));
  
    return result;
  };

  export const getUserCountry = () => {
    const result = axios
      .get("https://extreme-ip-lookup.com/json/?key=gqvGIbiwK4L6R2HZW82b")
      .then((res) => res)
      .catch((err) => handleError(err));
  
    return result;
  };

  export const getSentimentAndRelative = () => {
    const result = axios
      .get(`${API_BASE_URL}/sentiments`, {
        headers: { Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` },
      })
      .then((res) => res)
      .catch((err) => handleError(err));
  
    return result;
  };
  
  export const getCurrencyStrength = (dayRequest) => {
    const result = axios
      .get(`${API_BASE_URL}/currency-strengths?query=${dayRequest}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` },
      })
      .then((res) => res)
      .catch((err) => handleError(err));
  
    return result;
  };
  
  export const getInterestRates = () => {
    const result = axios
      .get(`${API_BASE_URL}/interest-rates`, {
        headers: { Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` },
      })
      .then((res) => res)
      .catch((err) => handleError(err));
  
    return result;
  };
  
  export const getSymbolNames = () => {
    const result = axios
      .get(`${API_BASE_URL}/symbols`, {
        headers: { Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` },
      })
      .then((res) => res)
      .catch((err) => handleError(err));
  
    return result;
  };

  export const getTransactionHistory = () => {
    const result = axios
      .get(`${API_BASE_URL}/transactions`, {
        headers: { Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` },
      })
      .then((res) => res)
      .catch((err) => handleError(err));
  
    return result;
  };

  export const postTransactionHistory = (data) => {
    const result = axios
      .post(`${API_BASE_URL}/transactions`, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` },
      })
      .then((res) => res)
      .catch((err) => handleError(err));
  
    return result;
  };

  export const getPaymentMethods = () => {
    const result = axios
      .get(`${API_BASE_URL}/payment-methods`, {
        headers: { Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` },
      })
      .then((res) => res)
      .catch((err) => handleError(err));
  
    return result;
  };

  export const getPromoCodes = () => {
    const result = axios
      .get(`${API_BASE_URL}/promotion-codes`, {
        headers: { Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` },
      })
      .then((res) => res)
      .catch((err) => handleError(err));
  
    return result;
  };