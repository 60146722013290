import {
  SIDE_NAV_LIGHT,
  DIR_LTR,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "Fortuna";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: "TOP",
  topNavColor: "#0F4DEB",
  headerNavColor: "#ffaa00",
  mobileNav: false,
  currentTheme: "dark",
  direction: DIR_LTR,
};

const langInRoute = window.location.pathname.split("/")[1];
export const AUTH_PREFIX_PATH =
  "/" +
  (["en", "fa"].includes(langInRoute) ? langInRoute : THEME_CONFIG.locale);
