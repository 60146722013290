import React, { useEffect, useState } from "react";
import {Menu, Tooltip, Row, Col, Switch, Button, Form, notification, Select} from "antd";
import Flex from "components/shared-components/Flex";
import { API_BASE_URL } from "../../../configs/AppConfig";
import {AUTH_TOKEN, LANG} from "../../../redux/constants/Auth";
import {BellOutlined, CheckOutlined} from "@ant-design/icons";
import axios from "axios";
import { Link } from "react-router-dom";
import setLocale from "constants/Translate";
import lang from "../../../assets/data/language.data.json";
const NotificationSettings = () => {
  const [notificationResponse, setNotificationResponse] = useState(null);
  const notificationList = [
    {
      label: setLocale(true, "analysis.title"),
      key: "analysis",
    },
    {
      label: setLocale(true, "marketinsights.title"),
      key: "marketInsights",
    },
    {
      label: setLocale(true, "weekahead.title"),
      key: "weekAhead",
    },
    // {
    //   label: setLocale(true, "economiccalendar.title"),
    //   key: "economicCalendar",
    // },
    {
      label: setLocale(true, "tradeideas.title"),
      key: "tradeIdeas",
    },
    {
      label: setLocale(true, "marketstream.title"),
      key: "news",
    },
  ];
  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/user/notification`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
      })
      .then((res) => setNotificationResponse(res.data))
      .catch((err) => console.log(err));
  }, []);
  const submitNotificationSettings = (values) => {
    axios
      .post(`${API_BASE_URL}/user/notification`, values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
      })
      .then((res) => {
        notification.success({
          message: "Success",
          description: "Settings updated successfully!",
        });
      })
      .catch((err) => {
        console.log(err);
        notification.error({
          message: "Error",
          description: "An error occurred!",
        });
      });
  };
  return (
    <Form onFinish={submitNotificationSettings}>
      <Row>
        <Col xs={24} sm={24} md={24} lg={8}>
          {notificationResponse &&
            notificationList.map((item) => (
              <Flex className="w-100" justifyContent="between" key={item.key}>
                <div className={"mt-2"}>{item.label}</div>
                <Form.Item
                  name={item.key}
                  valuePropName="checked"
                  initialValue={
                    notificationResponse &&
                    (notificationResponse[item.key] || false)
                  }
                >
                  <Switch className="ml-3" />
                </Form.Item>
              </Flex>
            ))}
        </Col>
      </Row>
      <Row>
        {
            notificationResponse &&
            <Col xs={24} sm={24} md={24} lg={8}>
              <Flex className="w-100" justifyContent="between">
                <span>{setLocale(true, "nav.profile.language")}</span>
                <Form.Item
                    name={'language'}
                    initialValue={notificationResponse.language}
                >
                <Select
                    placeholder={setLocale(true, "select language")}
                    defaultValue={
                      <div>
                        <img
                            style={{ maxWidth: "20px" }}
                            src={`/img/flags/${lang.filter(item => item.langId === notificationResponse.language)[0].icon}.png`}
                            alt={lang.filter(item => item.langId === notificationResponse.language)[0].langName}
                        />
                        <span className="font-weight-normal ml-2">{lang.filter(item => item.langId === notificationResponse.language)[0].langName}</span>
                      </div>
                    }
                    // style={{ width: "100%"}}
                    // onChange={handleShowSymbol}
                >
                  {lang.map((elm, i) => {
                    return (
                        <Menu.Item
                            key={elm.langId}
                        >
            <span className="d-flex justify-content-between align-items-center">
              <div>
                <img
                    style={{ maxWidth: "20px" }}
                    src={`/img/flags/${elm.icon}.png`}
                    alt={elm.langName}
                />
                <span className="font-weight-normal ml-2">{elm.langName}</span>
              </div>
              {/*{locale === elm.langId ? (*/}
              {/*    <CheckOutlined className="text-success" />*/}
              {/*) : null}*/}
            </span>
                        </Menu.Item>
                    );
                  })}
                </Select>
                </Form.Item>
              </Flex>
            </Col>
        }
      </Row>
      <Button className="mt-3" type="primary" htmlType="submit">
        {setLocale(true, "btn.submit")}
      </Button>
    </Form>
  );
};

export default NotificationSettings;

const NotificationMenu = () => {
  return (
    <>
      <Menu mode="horizontal">
        <Menu.Item >
          <Tooltip placement="topLeft" title={setLocale(true, "nav.tooltip.notification")}>
          <Link to="/profile/notification-settings" className="btn-primary"><BellOutlined className="nav-icon mr-0" /></Link>
          </Tooltip>
        </Menu.Item>
      </Menu>
    </>
  );
};

export {NotificationMenu};
