import React from "react";
import { APP_NAME } from "configs/AppConfig";
const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "4rem",
  background: "#383838",
  color: "#fff",
};
export default function Footer() {
  return (
    <footer style={style}>
      <span>
        Copyright &copy; {`${new Date().getFullYear()}`}{" "}
        <span className="font-weight-semibold">{`${APP_NAME}`}</span> All rights
        reserved.
      </span>
    </footer>
  );
}
