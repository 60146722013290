import React, { useEffect, useState } from "react";
import { RadarChartOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { getCurrencyStrength } from "constants/ApiConstant";
import CurrencyLineChart from "./CurrencyLineChart";
import { Button, Menu, Tooltip } from "antd";
import setLocale from "constants/Translate";

const CurrencyStrength = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currencyStrengthData, setCurrencyStrengthData] = useState([]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const updateChartData = (dayReq) => {
    getCurrencyStrength(dayReq).then((res) => {
      if (res.status === 200) {
        const data = {dayReq: dayReq, rateData: res.data}        
        setCurrencyStrengthData(data);
      }
    });
  }
  
  return (
    <>
        <Menu mode="horizontal">
          <Menu.Item  onClick={() => {const dayReq = "today";showModal();updateChartData(dayReq);}}>
            <Tooltip placement="topLeft" title={setLocale(true, "nav.tooltip.strength")}>
            <a href><RadarChartOutlined className="nav-icon mr-0" /></a>
            </Tooltip>
          </Menu.Item>
        </Menu>
        <Modal
          title={setLocale(true, "strength.chart.title")}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          width={1000}
          style={{ textAlign: "center" }}
        >
          <Button type="primary" className="ml-2 mr-2" onClick={() => {const dayReq = "today";updateChartData(dayReq);}}>{setLocale(true, "strength.chart.today")}</Button>
          <Button type="primary" className="ml-2 mr-2" onClick={() => {const dayReq = "yesterday";updateChartData(dayReq);}}>{setLocale(true, "strength.chart.yesterday")}</Button>
          
          <div style={{marginTop: 10}}><CurrencyLineChart data={currencyStrengthData} /></div>
        </Modal>
    </>
  );
};

export default CurrencyStrength;
