import setLocale from "constants/Translate"
import { APP_PREFIX_PATH } from "configs/AppConfig";
const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: setLocale(false,"home.title"),
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "analysis",
    path: `${APP_PREFIX_PATH}/analysis`,
    title: setLocale(false,"analysis.title"),
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "market-insights",
    path: `${APP_PREFIX_PATH}/market-insights`,
    title: setLocale(false,"marketinsights.title"),
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "week-ahead",
    path: `${APP_PREFIX_PATH}/week-ahead`,
    title: setLocale(false,"weekahead.title"),
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "market-stream",
    path: `${APP_PREFIX_PATH}/market-stream`,
    title: setLocale(false,"marketstream.title"),
    breadcrumb: false,
    submenu: [],
  },
  // {
  //   key: "economic-calendar",
  //   path: `${APP_PREFIX_PATH}/economic-calendar`,
  //   title: setLocale(false,"economiccalendar.title"),
  //   breadcrumb: false,
  //   submenu: [],
  // },
  {
    key: "trade-ideas",
    path: `${APP_PREFIX_PATH}/trade-ideas`,
    title: setLocale(false,"tradeideas.title"),
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
