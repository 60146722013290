import React, { useEffect, useState } from "react";
import { RocketOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { getInterestRates } from "constants/ApiConstant";
import RatesTable from "./RatesTable";
import { Menu, Tooltip } from "antd";
import setLocale from "constants/Translate";

const InterestRates = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [interestRatesData, setInterestRatesData] = useState([]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const rows = [];

    getInterestRates().then((res) => {
      if (res.status === 200) {
        res.data?.map(
          (
            {
              bankName,
              currency,
              interestRate,
              nextMeeting,
              probabilityOfChange,
              probabilityOfNotChange,
              forecast,
              flag,
            },
            index
          ) => {
            return rows.push({
              key: index,
              bankName,
              currency,
              interestRate,
              nextMeeting,
              probabilityOfChange,
              probabilityOfNotChange,
              forecast,
              flag,
            });
          }
        );
        setInterestRatesData(rows);
      }
    });
    return () => {
      setInterestRatesData([]);
    };
  }, [isModalVisible]);

  return (
    <>
      <Menu mode="horizontal">
          <Menu.Item  onClick={showModal}>
            <Tooltip placement="topLeft" title={setLocale(true, "nav.tooltip.interestrate")}>
            <a href><RocketOutlined className="nav-icon mr-0" /></a>
            </Tooltip>
          </Menu.Item>
        </Menu>
        <Modal
          title={setLocale(true, "interestrate.table.title")}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          width={"80%"}
          style={{ textAlign: "center" }}
        >
          <RatesTable data={interestRatesData} />
        </Modal>      
    </>
  );
};

export default InterestRates;
