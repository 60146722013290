export const SIGNIN = "SIGNIN";
export const USEREMAIL = "USEREMAIL";
export const USER_INFO = "USER_INFO";
export const AUTHENTICATED = "AUTHENTICATED";
export const SIGNOUT = "SIGNOUT";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SHOW_AUTH_MESSAGE = "SHOW_AUTH_MESSAGE";
export const HIDE_AUTH_MESSAGE = "HIDE_AUTH_MESSAGE";
export const SHOW_LOADING = "SHOW_LOADING";
export const AUTH_TOKEN = "auth_token";
export const LANG = "lang";
export const SIGNIN_WITH_GOOGLE = "SIGNIN_WITH_GOOGLE";
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED =
  "SIGNIN_WITH_GOOGLE_AUTHENTICATED";
export const SIGNIN_WITH_FACEBOOK = "SIGNIN_WITH_FACEBOOK";
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED =
  "SIGNIN_WITH_FACEBOOK_AUTHENTICATED";
export const COUNTRY = "Country";
export const COUNTRYPHONE = "Countryphone";
export const TIMEZONE = "TimeZone";
