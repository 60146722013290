import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import { 
  COLOR_SETNIMENT_1,  COLOR_SETNIMENT_2,  COLOR_SETNIMENT_3,
  COLOR_SETNIMENT_4,  COLOR_SETNIMENT_5,  COLOR_SETNIMENT_6,
  COLOR_SETNIMENT_7,
} from "constants/ChartConstant";
  

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SentimentBarChart = ({ data }) => {
  const [categories, setCategories] = useState([]);
  const [power, setPower] = useState([]);
  const [fillColors, setFillColors] = useState([]);

  useEffect(() => {
    if (data) {
      const newCat = [];
      const newPow = [];
      const newColor = [];
      data.map(({ currency, power }) => {
        newCat.push(currency);
        newPow.push(power);
        
        if (power === 1) {newColor.push(COLOR_SETNIMENT_1);}
        else if (power === 2) {newColor.push(COLOR_SETNIMENT_2);}
        else if (power === 3) {newColor.push(COLOR_SETNIMENT_3);}
        else if (power === -3) {newColor.push(COLOR_SETNIMENT_4);}
        else if (power === -2) {newColor.push(COLOR_SETNIMENT_5);}
        else if (power === -1) {newColor.push(COLOR_SETNIMENT_6);}
        else {newColor.push(COLOR_SETNIMENT_7);}
      });

      setCategories(newCat);
      setPower(newPow);
      setFillColors(newColor);
    }
  }, [data]);

  const chartData = {
    labels: categories,
    datasets: [
      {
        data: power,
        backgroundColor: fillColors,
        minBarLength: 50,
        datalabels: {
          display: false,
        }
      }
    ],
  };


  const chartOptions = {
    indexAxis: 'y',
    responsive: true,
    plugins:{
      tooltip:{
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      xAxis: {
        min: -3,
        max: 3,
        ticks: {
          color: '#fcfcfc',
          callback: function(label, index, labels) {
            switch (label) {
                case 1:
                    return 'Weak Bullish';
                case 2:
                    return 'Bullish';
                case 3:
                    return 'Strong Bullish';
                case -1:
                    return 'Weak Bearish';
                case -2:
                    return 'Bearish';
                case -3:
                    return 'Strong Bearish';
                case 0:
                    return 'Neutral';   
            }
        }
        }
      },
      yAxis: {
        ticks: {
          color: '#fcfcfc',
        }
      }
    }
  };


  return (
    <Bar options={chartOptions} data={chartData}/>
  );
};

export default SentimentBarChart;
