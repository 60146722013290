import React from "react";
import { Table, Tag } from "antd";
import moment from "moment";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { API_BASE_URL } from "configs/AppConfig";
import setLocale from "constants/Translate";

const RatesTable = ({ data }) => {
  const columns = [
    {
      title: setLocale(true, "interestrate.table.bankname"),
      dataIndex: "bankName",
    },
    {
      title: setLocale(true, "interestrate.table.currency"),
      align: "center",
      dataIndex: "currency",
    },
    {
      title: setLocale(true, "interestrate.table.country"),
      align: "center",
      dataIndex: "flag",
      render: (text, record) => {
        return (
          <img src={API_BASE_URL + text} alt="" style={{ width: "1.5rem" }} />
        );
      },
    },
    {
      title: setLocale(true, "interestrate.table.interestrate"),
      align: "center",
      dataIndex: "interestRate",
    },
    {
      title: setLocale(true, "interestrate.table.nextmeeting"),
      align: "center",
      dataIndex: "nextMeeting",
      render: (text, record) => {
        return moment(text).format("YYYY-MM-DD");
      },
    },
    {
      title: setLocale(true, "interestrate.table.forecast"),
      align: "center",
      dataIndex: "forecast",
      render: (text, record) => {
        let result;
        if (text > 0) {
          result = <Tag color="#1CC750">{text} bps</Tag>;
        } else if (text < 0) {
          result = <Tag color="#EA4224">{text} bps</Tag>;
        } else {
          result = <Tag color="#ffaa00">{text} bps</Tag>;
        }
        return result;
      },
    },
    {
      title: "",
      dataIndex: "interestRate",
      render: (text, record) => {
        let result;
        if (record.forecast > 0) {
          result = <ArrowUpOutlined style={{ color: "#1CC750" }} />;
        } else if (record.forecast < 0) {
          result = <ArrowDownOutlined style={{ color: "#EA4224" }} />;
        } else {
          result = <MinusOutlined style={{ color: "#ffaa00" }} />;
        }

        return result;
      },
    },
    {
      title: setLocale(true, "interestrate.table.probchange"),
      dataIndex: "probabilityOfChange",
      align: "center",
      render: (text, record) => {
        return `${text}%`;
      },
    },
    {
      title: setLocale(true, "interestrate.table.probnochange"),
      dataIndex: "probabilityOfNotChange",
      align: "center",
      render: (text, record) => {
        return `${text}%`;
      },
    },
  ];
  const dataSource = data;

  return (
    <div>
      <Table
        pagination={false}
        columns={columns}
        dataSource={dataSource}
        size="middle"
        style={{ textAlign: "center" }}
      />
    </div>
  );
};

export default RatesTable;
