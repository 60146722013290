import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={lazy(() => import(`./home`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/analysis`}
          component={lazy(() => import(`./analysis`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/market-insights`}
          component={lazy(() => import(`./marketInsights`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/week-ahead`}
          component={lazy(() => import(`./weekAhead`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/market-stream`}
          component={lazy(() => import(`./marketStream`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/economic-calendar`}
          component={lazy(() => import(`./economicCalendar`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/trade-ideas`}
          component={lazy(() => import(`./tradeIdeas`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/profile`}
          component={lazy(() => import(`./profile`))}
        />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
